import React, { useEffect, useState } from "react";
import Loading from "../LoaderPageSection/Loading";
import Layout from "../Layout/Content";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAuth } from "../../context/auth";
import { FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
const Content = ({ children }) => {
  const location = useLocation();
  const sideLinks = ["marketplace", "profile"];
  const isActive = (path) => {
    return location.pathname === path;
  };
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [copytext, setCopyText] = useState("copy");
  const [toggle, settoggle] = useState(false);
  const togglesideBar = () => {
    settoggle(!toggle);
  };
  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 10) +
        "...." +
        address.substr(address.length - 10, address.length)
      );
    }
    return address;
  };

  const handleCopy = () => {
    setCopyText("Copied!");
    let text = `https://stake.decentrawood.com/?${address}`;
    text.trim();
    navigator.clipboard.writeText(text);
  };

  setTimeout(() => {
    setCopyText("copy");
  }, 2000);
  useEffect(() => {
    if (
      auth?.user?.address &&
      auth?.user?.referralAddress &&
      auth?.user?.token
    ) {
      setAddress(auth?.user?.address);
    }
  }, [auth]);

  const handleIsDisconnect = () => {
    Swal.fire({
      text: "Are you sure you won't be Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let getLocalData = localStorage.getItem("auth");
          getLocalData = JSON.parse(getLocalData);
          const jwtToken = getLocalData.user?.token;

          const resp = await axios.post(`/api/user/logout-user`, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          });
          if (resp.status === 200) {
            if (resp?.data?.status === true) {
              toast.success(resp?.data?.message);
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            toast.error(err_401);
          } else if (error.response.status === 404) {
            const err_404 = error?.response?.data?.message;
            toast.error(err_404);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            toast.error(err_500);
          } else {
            console.log("Error in Register", error);
            toast.error("something went wrong");
          }
        }

        setAuth({
          ...auth,
          user: null,
        });
        localStorage.removeItem("auth");
        navigate("/");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };
  return (
    <>
      <div>
        <div className={`sidebar ${toggle && "show"}`}>
          <div className="toggle-btn close" onClick={togglesideBar}>
            <FaTimes />
          </div>
          <a href="/" className="logo">
            <img
              src={`/assets/satoshi-logo.png`}
              style={{ width: "145px" }}
              alt="img"
            />
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            {sideLinks.map((data, index) => {
              return (
                <li key={index}>
                  <Link
                    to={`/${data}`}
                    activeClassName="active"
                    className={`nav-link link-body-emphasis ${
                      isActive(`/${data}`) ? "active" : ""
                    }`}
                  >
                    <svg className="bi pe-none me-2" width={16} height={16}>
                      <use xlinkHref="#speedometer2" />
                    </svg>
                    {data.charAt(0).toUpperCase() + data.slice(1)}
                  </Link>
                </li>
              );
            })}
          </ul>
          <hr />
        </div>
      </div>
      <div className={`content ${toggle && "show"}`}>
        <div className="container">
          <div className="header-box">
            <div className="toggle-btn" onClick={togglesideBar}>
              <i className="bi bi-list"></i>
            </div>
            <div className="d-flex align-items-center">
              {auth?.user && (
                <>
                  <div className="dropdown ">
                    <div
                      className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle position-relative  walled-ad"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="https://github.com/mdo.png"
                        alt="error"
                        width={32}
                        height={32}
                        className="rounded-circle me-2"
                      />

                      <div
                        className="position-absolute"
                        style={{ top: `-15%`, right: `5%`, fontSize: "12px" }}
                      >
                        {auth?.user?.role === 1 && (
                          <span className="text-white bg-success rounded px-1">
                            Admin
                          </span>
                        )}
                      </div>
                      {start_and_end(auth?.user?.address)}
                    </div>
                    <ul className="dropdown-menu text-small shadow w-100">
                      {/* <li>
                        <a className="dropdown-item" href="#">
                          New project...
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Settings
                        </a>
                      </li> */}
                      {/* <li>
                        <Link to={"/profile"} className="dropdown-item">
                          Profile
                        </Link>
                      </li> */}
                      <li>
                        <div
                          onClick={handleIsDisconnect}
                          className="dropdown-item"
                        >
                          Sign out
                        </div>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Content;
