import React from "react";
import { InfinitySpin, Triangle } from "react-loader-spinner";
import { useState, useEffect } from "react";
import "./styles.css";
const Loading = (props) => {
  const [loadingVisiblity, setLoadingVisiblity] = useState("none");
  useEffect(() => {
    if (props.loading) {
      setLoadingVisiblity("flex");
    } else {
      setLoadingVisiblity("none");
    }
  }, [props.loading]);
  return (
    <div className="loading" style={{ display: loadingVisiblity }}>
      <div className="text-center">
        <Triangle
          height="80"
          width="80"
          color="#0eb7d1"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
        <h5 style={{ color: "#0dcaf0", fontWeight: "bold", marginTop: "31px" }}>
          {/* Please waiting transaction will be completed.... */}
          Please wait ....
        </h5>
      </div>
    </div>
  );
};

export default Loading;
