import React, { useEffect, useState } from "react";
import Loading from "../LoaderPageSection/Loading";
import Layout from "../Layout/Content";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAuth } from "../../context/auth";
import { FaTimes } from "react-icons/fa";

const Content = ({ children }) => {
  const location = useLocation();
  const sideLinks = [
    // `admin-vesting1`, 
    "admin-buying"
  ];
  const isActive = (path) => {
    return location.pathname === path;
  };
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth({});
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [copytext, setCopyText] = useState("copy");
  const [toggle, settoggle] = useState(false);
  const togglesideBar = () => {
    settoggle(!toggle);
  };
  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 10) +
        "...." +
        address.substr(address.length - 10, address.length)
      );
    }
    return address;
  };

  const handleCopy = () => {
    setCopyText("Copied!");
    let text = `https://stake.decentrawood.com/?${address}`;
    text.trim();
    navigator.clipboard.writeText(text);
  };

  setTimeout(() => {
    setCopyText("copy");
  }, 2000);
  useEffect(() => {
    if (
      auth?.user?.address &&
      auth?.user?.referralAddress &&
      auth?.user?.token
    ) {
      setAddress(auth?.user?.address);
    }
  }, [auth]);

  const handleIsDisconnect = () => {
    setAuth({
      ...auth,
      user: null,
    });
    localStorage.removeItem("auth");
    navigate("/");
  };
  return (
    // <>
    //   <Layout title={"Landing page"}>
    //     <div className="poke-bg">
    //       <div className="container py-4">
    //         <nav className="navbar bg-offcanwas-nav border-none shadow-none">
    //           <div className="container">
    //             <a
    //               className="navbar-brand text-light"
    //               data-bs-toggle="offcanvas"
    //               href="#offcanvasExample"
    //               role="button"
    //               aria-controls="offcanvasExample"
    //             >
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="25"
    //                 height="25"
    //                 fill="#fff"
    //                 class="bi bi-list"
    //                 viewBox="0 0 16 16"
    //               >
    //                 <path
    //                   fill-rule="evenodd"
    //                   d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
    //                 />
    //               </svg>
    //             </a>
    //           </div>
    //         </nav>
    //       </div>
    //       {/* offcanwas */}

    //       <div
    //         class="offcanvas offcanvas-start offcanvas-start-cus"
    //         tabindex="-1"
    //         id="offcanvasExample"
    //         aria-labelledby="offcanvasExampleLabel"
    //       >
    //         <div class="offcanvas-header">
    //           <h5 class="offcanvas-title" id="offcanvasExampleLabel">
    //             Staking
    //           </h5>
    //           <button
    //             type="button"
    //             class="btn shadow-none"
    //             data-bs-dismiss="offcanvas"
    //             aria-label="Close"
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               width="25"
    //               height="25"
    //               fill="#fff"
    //               class="bi bi-x-circle"
    //               viewBox="0 0 16 16"
    //             >
    //               <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
    //               <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
    //             </svg>
    //           </button>
    //         </div>
    //         <div className="offcanwas-body">
    //           <div className="sidebar-link">
    //             <ul className="side-ul">
    //               <li
    //                 className="dash-active"
    //                 onClick={() => navigate("/admin-stakeone")}
    //               >
    //                 <Link>
    //                   {" "}
    //                   <i class="bi bi-speedometer2"></i>Dashboard
    //                 </Link>
    //               </li>
    //               <li onClick={() => navigate("/admin-stakeone")}>
    //                 <Link to={"/stakeone"}>
    //                   <i class="bi bi-stack"></i> Admin Stake 1
    //                 </Link>
    //               </li>
    //               <li onClick={() => navigate("/admin-staketwo")}>
    //                 <Link>
    //                   {" "}
    //                   <i class="bi bi-stack"></i> Admin Stake 2
    //                 </Link>
    //               </li>
    //               <li onClick={() => navigate("/admin-stakethree")}>
    //                 <Link>
    //                   {" "}
    //                   <i class="bi bi-stack"></i> Admin Stake 3
    //                 </Link>
    //               </li>
    //             </ul>
    //           </div>
    //         </div>
    //       </div>
    //       {/* end-canwas */}
    //       {children}
    //     </div>
    //   </Layout>
    // </>
    <>
      <div>
        <div className={`sidebar ${toggle && "show"}`}>
          <div className="toggle-btn close" onClick={togglesideBar}>
            <FaTimes />
          </div>
          <a href="/" className="logo">
            <img src={`assets/satoshi-Logo.png`} style={{ width: "145px" }} />
            {/* <span className="fs-4"> Admin Deod</span> */}
          </a>
          <hr />
          <ul className="nav nav-pills flex-column mb-auto">
            {sideLinks.map((data, index) => {
              return (
                <li key={index}>
                  <Link
                    to={`/${data}`}
                    activeClassName="active"
                    className={`nav-link link-body-emphasis ${
                      isActive(`/${data}`) ? "active" : ""
                    }`}
                  >
                    <svg className="bi pe-none me-2" width={16} height={16}>
                      <use xlinkHref="#speedometer2" />
                    </svg>
                    {data.charAt(0).toUpperCase() + data.slice(1)}
                  </Link>
                </li>
              );
            })}
          </ul>
          <hr />
        </div>
      </div>
      <div className={`content ${toggle && "show"}`}>
        <div className="container">
          <div className="header-box">
            <div className="toggle-btn" onClick={togglesideBar}>
              <i className="bi bi-list"></i>
            </div>

            {/* <div className="dropdown">
            <a
              href="#"
              className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >

              <img
                src="https://github.com/mdo.png"
                alt="error"
                width={32}
                height={32}
                className="rounded-circle me-2"
              />
            </a>
            <ul className="dropdown-menu text-small shadow "  >
              <li>
                <a className="dropdown-item" href="#">
                  New project...
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#">
                  Sign out
                </a>
              </li>
            </ul>
          </div> */}
            <div className="d-flex align-items-center">
              {auth?.user && (
                <>
                  <div className="dropdown ">
                    <div
                      className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle position-relative  walled-ad"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="https://github.com/mdo.png"
                        alt="error"
                        width={32}
                        height={32}
                        className="rounded-circle me-2"
                      />

                      <div
                        className="position-absolute"
                        style={{ top: `-15%`, right: `5%`, fontSize: "12px" }}
                      >
                        {auth?.user?.role !== 1 && (
                          <span className="text-white bg-success rounded px-1">
                            Admin
                          </span>
                        )}
                      </div>
                      {start_and_end(auth?.user?.address)}
                    </div>
                    <ul className="dropdown-menu text-small shadow w-100">
                    
                      <li>
                        <div
                          onClick={handleIsDisconnect}
                          className="dropdown-item"
                        >
                          Sign out
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <button className="d-flex btn walled-ad mx-2">
              <div className="me-2">
                <span className="user-icon">
                  <i class="bi bi-person-circle text-white mx-1"></i>
                </span>
                {auth?.user?.role === 0 ? (
                  <span className="text-white">User</span>
                ) : (
                  <span className="text-white">Admin</span>
                )}
              </div>
                <span className="user-icon">
                  <i class="bi bi-wallet2 "></i>
                </span>
                {start_and_end(auth?.user?.address)}
              </button> */}
                </>
              )}

              {/* <form class="form-box my-3" onSubmit={handleIsDisconnect}>
              <button className="btn connect-btn" type="submit">
                Disconnect
              </button>
            </form> */}
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Content;
