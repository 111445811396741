import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import axios from "axios";
import { ethers } from "ethers";
import Loading from "../LoaderPageSection/Loading";
const Content = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [refferalAddress, setRefferalAddress] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 18) +
        "...." +
        address.substr(address.length - 18, address.length)
      );
    }
    return address;
  };
  console.log("refferalAddress", refferalAddress);
  const handleRegister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const resp = await axios.post(`/api/user/create-user`, {
        accountId: userAddress,
        referralId: refferalAddress,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message);
          setAuth({
            ...auth,
            user: null,
          });
          localStorage.removeItem("auth");
          navigate("/");
        }
        if (resp?.data?.status === false) {
          toast.error(resp?.data?.message);
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        setAuth({
          ...auth,
          user: null,
        });
        localStorage.removeItem("auth");
        navigate("/");
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const defaultRefferalAddress = "";
    const refferal = auth?.user?.referralAddress;
    const isCheckAddress = refferal === "" ? defaultRefferalAddress : refferal;
    if (auth?.user?.address !== "") {
      setUserAddress(auth?.user?.address);
      setRefferalAddress(isCheckAddress);
    }
  }, [auth]);
  console.log(userAddress);
  return (
    <Layout title={"Landing page"}>
      <div className="home-bg">
        {loading && <Loading loading={loading} />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="allotment-box">
                <form onSubmit={handleRegister}>
                  {/* <form> */}
                  <div className="row">
                    <div className="col-12">
                      <h4
                        className="text-light float-end cus-cancelbtn"
                        onClick={() => navigate("/")}
                      >
                        X
                      </h4>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          Connected Wallet
                        </label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={start_and_end(userAddress)}
                          disabled
                        />
                      </div>
                    </div>
                    {refferalAddress !== "" && (
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label cus-label">
                            Referral Wallet
                          </label>
                          <input
                            type="text"
                            className="form-control cus-control-form"
                            value={start_and_end(refferalAddress)}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn connect-btn my-3">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
