import { Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import AdminDashbordPage from "./components/pages/AdminDashbordPage";
import UserDashbordPage from "./components/pages/UserDashbordPage";
import RegisterPage from "./components/pages/RegisterPage";
import AdminStakeOne from "./components/sections/AdminPageSection/AdminstackOne";
import AdminStakeTwo from "./components/sections/AdminPageSection/AdminstackTwo";
import AdminStakeThree from "./components/sections/AdminPageSection/AdminstackThree";
import MarketPlacePage from "./components/sections/UserPageSection/Marketplace";
import NFTDetails from "./components/sections/UserPageSection/NftDetails";
import Profile from "./components/sections/UserPageSection/Profile";
function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/admin-dashbord" element={<AdminDashbordPage />} />
      <Route path="/admin-vesting1" element={<AdminStakeOne />} />
      <Route path="/admin-buying" element={<AdminStakeTwo />} />
      <Route path="/admin-stakethree" element={<AdminStakeThree />} />
      <Route path="/user-dashbord" element={<UserDashbordPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/:id" element={<RegisterPage />} />
      <Route path="/marketplace" element={<MarketPlacePage />} />
      {/* <Route path="/nft-details" element={<NFTDetails />} /> */}
      <Route path="/nft-details/:id" element={<NFTDetails />} />
      <Route path="/profile" element={<Profile />} />
    </Routes>
  );
}

export default App;
