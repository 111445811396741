import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../../image/Logo.png";
import Swal from "sweetalert2";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../LoaderPageSection/Loading";
import RegisterPageSection from "../RegisterPageSection/Content";
import { StakeAbi, StakeAddress } from "../Abi/Content";
import { ethers } from "ethers";
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [auth, setAuth] = useAuth();

  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 10) +
        "...." +
        address.substr(address.length - 10, address.length)
      );
    }
    return address;
  };
  const handleIsConnectApi = async (address) => {
    try {
      const resp = await axios.post(`/api/user/login-user`, {
        accountId: address,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === false) {
          const message = resp?.data?.message;
          if (message === "User Does not Exists") {
            toast.error("Please Register");
          }
        }
        if (resp?.data?.status === true) {
          const message = resp?.data?.message;
          const { accountId, referredBy } = resp?.data?.data;
          if (message === "User login successful!") {
            const isAdmin = [
              // "0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2",
              // "0x65c0cb0e58d0a45d294bc0d1c37ee8c714e1372d",
              // "0xab40155eadeba5223cd26d2dca9fb8a4a3393fa9",
                 "0x9ede3b271062298bcf9e987284e681da687eb5b1"
            ];
            var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
            setAuth({
              ...auth,
              user: {
                address: accountId,
                referralAddress: referredBy,
                role: role,
                token: resp?.data?.token,
              },
            });
            localStorage.setItem(
              "auth",
              JSON.stringify({
                user: {
                  address: accountId,
                  referralAddress: referredBy,
                  role: role,
                  token: resp?.data?.token,
                },
              })
            );
            localStorage.setItem("myToggled", 0);
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp?.data?.token}`;
            let data = localStorage.getItem("auth");
            data = JSON.parse(data);
            const admin = data?.user?.role;
            if (admin === 1) {
              navigate("/admin-buying");
            } else {
              navigate("/marketplace");
            }
          }
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const message = [
        "Welcome to the RoyalNFT!",
        "Please sign this message to verify your identity.",
        "Please sign in!",
      ].join("\n\n");
      await signer.signMessage(message);
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      setAddress(address);
      await handleIsConnectApi(address);
    } catch (error) {
      console.log("wallet loagin Error", error);
    }
    setLoading(false);
  };

  const handleIsDisconnect = () => {
    Swal.fire({
      text: "Are you sure you won't be Logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let getLocalData = localStorage.getItem("auth");
          getLocalData = JSON.parse(getLocalData);
          const jwtToken = getLocalData.user?.token;

          const resp = await axios.post(`/api/user/logout-user`, {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          });
          if (resp.status === 200) {
            if (resp?.data?.status === true) {
              toast.success(resp?.data?.message);
            }
          }
        } catch (error) {
          if (error.response.status === 400) {
            var err_400 = error?.response?.data?.message;
            toast.error(err_400);
          } else if (error.response.status === 401) {
            const err_401 = error?.response?.data?.message;
            toast.error(err_401);
          } else if (error.response.status === 404) {
            const err_404 = error?.response?.data?.message;
            toast.error(err_404);
          } else if (error.response.status === 500) {
            const err_500 = error?.response?.data?.message;
            toast.error(err_500);
          } else {
            console.log("Error in Register", error);
            toast.error("something went wrong");
          }
        }

        setAuth({
          ...auth,
          user: null,
        });
        localStorage.removeItem("auth");
        navigate("/");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("Cancelled");
      }
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        {loading && <Loading loading={loading} />}
        <div className="container">
          <Link className="navbar-brand">
            <img
              src={`/assets/satoshi-logo.png`}
              alt="logo"
              style={{ width: "94px" }}
            />
          </Link>

          <button
            className="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#fff"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              {/* Add Home and White Paper links */}
              <li className="nav-item">
                <Link className="nav-link text-success" to="/" >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/assets/landingpage/Royal NFT Whitepaper.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#03042e' }}
                >
                  White Paper
                </a>
              </li>
            </ul>

            {!auth?.user ? (
              <form className="form-box my-3" onSubmit={handleSubmit}>
                <button className="btn connect-btn" type="submit">
                  Connect
                </button>
              </form>
            ) : (
              <div className="d-flex align-items-center">
                {auth?.user && (
                  <>
                    <div className="dropdown ">
                      <div
                        className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle position-relative  walled-ad"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="https://github.com/mdo.png"
                          alt="error"
                          width={32}
                          height={32}
                          className="rounded-circle me-2"
                        />
                        <div
                          className="position-absolute"
                          style={{ top: `-15%`, right: `5%`, fontSize: "12px" }}
                        >
                          {auth?.user?.role === 1 && (
                            <span className="text-white bg-success rounded px-1">
                              Admin
                            </span>
                          )}
                        </div>
                        {start_and_end(auth?.user?.address)}
                      </div>
                      <ul className="dropdown-menu text-small shadow w-100">
                        <li>
                          <div
                            onClick={handleIsDisconnect}
                            className="dropdown-item"
                          >
                            Sign out
                          </div>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </nav>

    </>
  );
};

export default Header;
