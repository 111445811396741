import React, { useEffect, useState } from "react";
import AdminPageSection from "./Content";
import { ethers } from "ethers";
import { StakeAddressTwo, StakeAbiTwo } from "../Abi/Content";
import Loading from "../LoaderPageSection/Loading";
import { toast } from "react-toastify";
import axios from "axios";
const AdminstackTwo = () => {
  const [loading, setLoading] = useState(false);
  const [buyingData, setBuyingData] = useState([]);

  const getByingReport = async () => {
    try {

      const response = await axios.get(`/api/admin/get-all-ordered-assets`);
      if (response && response.data) {
        const { getAllAssets, status } = response.data;
        console.log("response.data", response.data);

        if (status === true && getAllAssets) {
          setBuyingData(getAllAssets)
        }
      }
    } catch (error) {
      console.log("Error in patients data", error);
    }
  }

  useEffect(() => {

    getByingReport();
  }, []);


  return (
    <AdminPageSection>
      {loading && <Loading loading={loading} />}
      <div className="container">
        <div className="row my-5">
          <div className="col-12">
            <div className="adminbox-caption">
              <div className="tab-content">
                <div className="tab-pane fade show active ">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Asset Id</th>
                          <th scope="col">Address</th>
                          <th scope="col">Asset Name</th>
                          <th scope="col">Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">totalPrice</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buyingData &&
                          buyingData.map((value, key) => {
                            return (
                              <tr>
                                <th scope="row">{key + 1}</th>
                                <td>{value?.assetId}</td>
                                <td>{value?.accountId}</td>
                                <td>{value?.assetName}</td>
                                <td>{value?.totalPrice}</td>
                                <td>{value?.quantity}</td>
                                <td>{value?.totalPrice}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminPageSection>
  );
};

export default AdminstackTwo;
