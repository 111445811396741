import React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import { toast } from "react-toastify";

const Content = ({ children, title, description, keywords, author }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Header />
      <main className="main-box">{children}</main>
    </div>
  );
};

export default Content;
