import React, { useEffect, useState } from "react";
import "../AdminPageSection/styles.css";
import { ethers } from "ethers";
import { StakeAbi, StakeAddress } from "../Abi/Content";
import Loading from "../LoaderPageSection/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AdminPageSection from "./Content";
import deodLogo from "../../../image/Logo.png";
import UsdtLogo from "../../../image/tether-logo.png";
import { useAuth } from "../../context/auth";
import axios from "axios";
const AdminstackOne = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth({});

  const [loading, setLoading] = useState(false);
  const [getFirstYear, setGetFirstYear] = useState("");
  const [getSecondYear, setGetSecondYear] = useState("");
  const [getThirdYear, setGetThirdYear] = useState("");
  const [getAllSingleData, setGetAllSingleData] = useState({});
  const [getAllRefferalSingleData, setGetAllRefferalSingleData] = useState({});
  const [refferalFirstYear, setRefferalFirstYear] = useState("");
  const [refferalSecondYear, setRefferalSecondYear] = useState("");
  const [refferalThirdYear, setRefferalThirdYear] = useState("");
  const [percentage, setPercentage] = useState("");
  const [refferalPercentage, setRefferalPercentage] = useState("");
  const [isToggled, setIsToggled] = useState(0);
  const [totalToken, setTotalToken] = useState("0");
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [finalTotalAmount, setFinalTotalAmount] = useState(0);
  const [availableClaim, setAvailableClaim] = useState("0");
  const [totalClaimToken, setTotalClaimToken] = useState("0");
  let getLocalData = localStorage.getItem("auth");
  getLocalData = JSON.parse(getLocalData);

  const allYearData = [
    {
      id: "1",
      year: "1 Year",
      day: 365,
      percentage: getFirstYear,
    },
    {
      id: "2",
      year: "2 Year",
      day: 730,
      percentage: getSecondYear,
    },
    {
      id: "3",
      year: "3 Year",
      day: 1095,
      percentage: getThirdYear,
    },
  ];

  const RefferalData = [
    {
      id: "1",
      year: "1 Year",
      day: 365,
      percentage: refferalFirstYear,
    },
    {
      id: "2",
      year: "2 Year",
      day: 730,
      percentage: refferalSecondYear,
    },
    {
      id: "3",
      year: "3 Year",
      day: 1095,
      percentage: refferalThirdYear,
    },
  ];

  const loadData = async () => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);
      let getFirstYearPercentage = await StakeContract.getBonusFromDays(365);
      getFirstYearPercentage = parseFloat(getFirstYearPercentage) / 10;
      setGetFirstYear(getFirstYearPercentage);

      let getSecondYearPercentage = await StakeContract.getBonusFromDays(730);
      getSecondYearPercentage = parseFloat(getSecondYearPercentage) / 10;
      setGetSecondYear(getSecondYearPercentage);

      let getThirdYearPercentage = await StakeContract.getBonusFromDays(1095);
      getThirdYearPercentage = parseFloat(getThirdYearPercentage) / 10;
      setGetThirdYear(getThirdYearPercentage);

      // refferal year percentage

      let getFirstYearRefferalPercentage =
        await StakeContract.getReferalBonusFromDays(365);
      getFirstYearRefferalPercentage =
        parseFloat(getFirstYearRefferalPercentage) / 10;
      setRefferalFirstYear(getFirstYearRefferalPercentage);

      let getSecondYearRefferalPercentage =
        await StakeContract.getReferalBonusFromDays(730);
      getSecondYearRefferalPercentage =
        parseFloat(getSecondYearRefferalPercentage) / 10;
      setRefferalSecondYear(getSecondYearRefferalPercentage);

      let getThirdYearRefferalPercentage =
        await StakeContract.getReferalBonusFromDays(1095);
      getThirdYearRefferalPercentage =
        parseFloat(getThirdYearRefferalPercentage) / 10;
      setRefferalThirdYear(getThirdYearRefferalPercentage);
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleUpdateYearPercentage = async (days, percentage) => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);
      let tx = await StakeContract.setBounsPercentage(days, percentage * 10);
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload(false);
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleUpdateRefferalPercentage = async (days, refpercentage) => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);
      let tx = await StakeContract.setReferalPercentageFromDays(
        days,
        refpercentage * 10
      );
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload(false);
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleClaim = async () => {
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const storeAddress = auth?.user?.address;
      if (address === storeAddress) {
        const StakeContract = new ethers.Contract(
          StakeAddress,
          StakeAbi,
          signer
        );
        const txn = await StakeContract.withdrawTokens(address);
        const receipttxn1 = await txn.wait();
        if (receipttxn1.status !== 1) {
          toast.error("Error");
        } else {
          const hash = await receipttxn1.transactionHash;
          await handleClaimAPI(hash);
        }
      } else {
        toast.error("Connect correct wallet");
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
      toast.error(`${JSON.stringify(error.reason)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false);
    window.location.reload(false);
  };

  const handleClaimAPI = async (trxHash) => {
    const token = auth?.user?.token;
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // You can adjust this as needed
    };
    const bodyData = {
      amount: availableClaim,
      transactionHash: trxHash,
    };
    try {
      const resp = await axios.post(`/api/user/stakeV1Withdrawal`, bodyData, {
        headers,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          window.location.reload(false);
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };


  useEffect(() => {
    async function handleData() {
      await loadData();
    }
    handleData();
  }, []);

  useEffect(() => {
    if (getLocalData === null) {
      navigate("/");
    }
  }, [getLocalData]);

  useEffect(() => {
    const myToggled = localStorage.getItem("myToggled");
    if (myToggled === null || myToggled === undefined) {
      setIsToggled(0);
    } else {
      setIsToggled(myToggled);
    }
    console.log("myToggled", myToggled);
  }, [isToggled]);
  return (
    <div>
      <AdminPageSection>
        {loading && <Loading loading={loading} />}

          {/* <div className="container">
            <div className="row my-5">
              <div className="col-12">
              </div>
            </div>
            <div className="row my-5">
              <div className="col-12">
                <div className="adminbox-caption">
                  <div className="tab-content w-50 mx-auto">
                  <h5 className="text-center my-3" style={{color:"#60be77"}}>
                            Monthly Release Percentage
                          </h5>
                         <div className="row justify-content-center">
<div className="col-6 text-center">
  <div>

  <label htmlFor="Current Percentage">Current Percentage</label>
  <input type="number" className="form-control bg-dark text-light my-2" value={35} disabled/>
  </div>
  <div>
<label htmlFor="Current Percentage">Set Percentage</label>
<input type="number" className="form-control bg-dark text-light my-2" />
</div>
<button className="btn connect-btn my-2">
  Set Monthly Percentage 
</button>
</div>

                         </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
              <div>
        <div className="row">
          {/* <div className="col-6">
            <button
              className="btn connect-btn mx-2 boobtn"
              // onClick={() => navigate("/stack-token")}
            >
              Stake Token
            </button>
          </div> */}
        <div className="col-12 col-md-4">
        <div className="row my-4">
          <div className="col-12">
          <div className="card cus-card-token-ad text-center" style={{minHeight:'40vh'}}>
              <div className="card-body">
                <div>
                <h6 className="text-start"> 
                <img src={UsdtLogo} className="deod-logo" alt="USDT" />
                USDT / 
                  <img src='/assets/satoshi-symbol.png' className="mx-1 img-fluid" style={{height:'28px'}} alt="Deod" />
                SATOSHIFX</h6>
                </div>
                <div className="d-flex justify-content-between" style={{fontSize:`13px`}}>
                  <div>
                    Price:
                  </div>
                  <div>
                    1 $ = 400 SFX
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{background:'#232323',borderRadius:'10px'}}>
                  <div className="">
                  <button className="px-2"  style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>!</button>
                  </div>
                  <div className="w-75" >
                  <input className='w-100' style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}} onWheel={(e) => e.target.blur()} type="text" value={`Limit`} disabled/>
                  </div>
                  <div>
                  <button className="px-2"  style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>▼</button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{background:'#232323',borderRadius:'10px'}}>
                  <div className="">
                  <button className="px-2"  style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>-</button>
                  </div>
                  <div className="w-75" >
                  <input className='w-100' style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}} onWheel={(e) => e.target.blur()} type="number" />
                  </div>
                  <div>
                  <button className="px-2"  style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>+</button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center my-3 p-2" style={{background:'#232323',borderRadius:'10px'}}>
                  <div className="">
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>-</button>
                  </div>
                  <div className="w-75">
                  <input className='w-100 input-sale'  type="text" value={'Amount (USDT)'} disabled style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}} />
                  </div>
                  <div>
                  <button className="px-2" style={{background:'#232323',border:'none',color: 'white',textAlign:'center'}}>+</button>
                  </div>
                </div>
                <div>
                  <button className="btn connect-btn" style={{fontWeight:'700'}}>BUY SATOSHIFX</button>
                </div>
              </div>
           
            </div>
          </div>
        </div>
        </div> 
        <div className="col-12 col-md-8">
        <div className="row justify-content-center my-4">
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Staked Token</h6>
              </div>
              <p className="mt-1">{totalToken}</p>
              <p className="mt-1">
                <span>
                  <img src='/assets/satoshi-symbol.png' className="mx-1 img-fluid" style={{height:'28px'}} alt="Deod" />
                </span>
                {totalToken}
                {"  "}SFX = 
                <span>
                  <img src={UsdtLogo} className="deod-logo" alt="USDT" />
                </span>{" "}
                 {usdtPrice} $ 
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Bonus Amount</h6>
              </div>
              <p className="mt-3">
                {(+finalTotalAmount - +totalToken).toFixed(2)}
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Total Amount</h6>
              </div>
              <p className="mt-3">{(+finalTotalAmount).toFixed(2)}</p>
            </div>
          </div>
          {/* <div className="col-12 col-md-4">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Referral Earning</h6>
              </div>
              <p className="mt-3">{refferalEarn}</p>
           
            </div>
          </div> */}
          <div className="col-12 col-md-6">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Available for Withdrawal</h6>
              </div>
              <p className="mt-3">{availableClaim}</p>
              <div className="text-center">
                <button
                  className="btn connect-btn"
                  onClick={() => handleClaim()}
                >
                  Claim Token
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card cus-card-token-ad text-center">
              <div className="card-body">
                <h6>Total Withdrawal</h6>
              </div>
              <p className="mt-3">{totalClaimToken}</p>
            </div>
          </div>
        </div>
        </div>
      
        </div>
        {/* <div class="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Staked Amount</th>
                <th scope="col">Bonus Amount</th>
                <th scope="col">Total Amount</th>
                <th scope="col">Percentage</th>
                <th scope="col">Staking Date</th>
                <th scope="col">Release Date</th>
              </tr>
            </thead>
            <tbody>
              {stackingData &&
                stackingData?.map((value, key) => {
                  return (
                    <tr>
                      <th scope="row">{key + 1}</th>
                      <td>{value?.depositAmount}</td>
                      <td>{value?.bonusAmount}</td>
                      <td>{value?.totalAmount}</td>
                      <td>{value?.percentage}</td>
                      <td>{value?.depositDateFinal}</td>
                      <td>{value?.maturityDateFinal}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div> */}
      </div>
      </AdminPageSection>
    </div>
  );
};

export default AdminstackOne;
