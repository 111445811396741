import React, { useEffect, useState } from "react";
import "./styles.css";
import Layout from "../Layout/Content";
import Logo from "../../../image/Logo.png";
import RegisterImg from "../../../image/register-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import axios from "axios";
const Content = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [refferal, setRefferal] = useState("");
  const location = useLocation();
  const [totalDeodStack, setTotaldeodStack] = useState();
  const handleIsRegisterApi = async (address) => {
    try {
      const resp = await axios.post(`/api/user/register-user`, {
        accountId: address,
        referralId: refferal,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === false) {
          toast.error(resp?.data?.message);
        }
        if (resp?.data?.status === true) {
          const isAdmin = ["0x7f967ec284ce3e3e3503c19db74dc697aea4c0f2"];
          var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
          setAuth({
            ...auth,
            user: {
              address: resp?.data?.data?.userAccount,
              referralAddress: resp?.data?.data?.referredBy,
              role: role,
            },
          });
          localStorage.setItem(
            "auth",
            JSON.stringify({
              user: {
                address: resp?.data?.data?.userAccount,
                referralAddress: resp?.data?.data?.referredBy,
                role: role,
              },
            })
          );
          navigate("/register");
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const handleIsregister = async () => {
    // e.preventDefault();
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const message = [
        "Welcome to the RoyalNFT !",
        "Please sign this message to verify your identity.",
        "Please sign in!",
      ].join("\n\n");
      await signer.signMessage(message);
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      await handleIsRegisterApi(address);
    } catch (error) {
      console.log("wallet loagin Error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location?.search !== "") {
      var dataSplit = location?.search.split("/");
      dataSplit = dataSplit[0].split("?")[1];
      setRefferal(dataSplit);
    }
  }, [location?.pathname]);
  return (
    <Layout>
      <div>
        {/* banner section */}
        <section className="banner-box">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-12">
                <div className="caption-box">
                  <h2> Where Timeless Artistry Meets Digital Innovation</h2>
                  <p>
                    Experience the luxury of handcrafted carpets like never
                    before with Royal NFT. Specializing in the creation of
                    exquisite digital representations of traditional carpets, we
                    bring the rich heritage and unparalleled craftsmanship of
                    this ancient art form into the digital age. Explore our
                    collection and own a piece of timeless beauty.
                  </p>
                  <div className="">
                    <button
                      className="register-btn"
                      onClick={() => handleIsregister()}
                    >
                      Register
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container py-5" id="about">
            <div className="row ">
              <div className="col-12">
                <div className="heading-box">
                  <h2>About us</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="logo-caption">
                  <h1>Royal NFT</h1>
                </div>
              </div>
              <div className="col-12 py-3">
                <div className="about-caption">
                  <p>
                    Welcome to Royal NFT, where timeless artistry meets modern
                    innovation. At Royal NFT, we specialize in creating
                    exquisite digital representations of handcrafted carpets,
                    each a testament to the rich heritage and unparalleled
                    craftsmanship of traditional carpet making. Our mission is
                    to bring the beauty and luxury of these timeless pieces into
                    the digital age, offering collectors and enthusiasts a
                    unique opportunity to own a piece of art that transcends
                    time and space. Our team consists of highly skilled artisans
                    and digital experts who collaborate to transform
                    meticulously handcrafted carpets into stunning NFTs. Each
                    piece we offer is a celebration of intricate design, vibrant
                    colors, and the highest quality materials, reflecting the
                    rich cultural heritage of carpet-making traditions from
                    around the world. By merging this ancient art form with
                    cutting-edge technology, we ensure that the beauty and value
                    of these masterpieces are preserved and appreciated by
                    future generations. At Royal NFT, we are committed to
                    authenticity, quality, and innovation. We work closely with
                    master craftsmen to ensure that every carpet we digitize
                    maintains its original beauty and integrity. Our NFTs are
                    not just digital assets; they are timeless treasures that
                    offer a new way to experience and invest in art. Join us on
                    this journey to explore the fusion of tradition and
                    technology. Discover the elegance and opulence of our
                    handcrafted carpet NFTs and become a part of the Royal NFT
                    family.
                  </p>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center" style={{width:'100vw'}}>
                <video controls style={{width:'80vw'}} controlsList="nodownload">
                  <source src="/assets/royal-video.mp4" type="video/mp4"/>
                  <source src="/assets/royal-video.ogg" type="video/ogg"/>
                </video>
              </div>
            </div>
          </div>
        </section>
        {/* service */}
        <section className="service-bg" id="service">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="logo-caption">
                  <h1>Royal NFT Services</h1>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.44 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.43 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.39 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.34 PM (1).jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.34 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.35 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.36 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.46 PM (1).jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.33 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <div className="img-box">
                  <img
                    src="/assets/landingpage/WhatsApp Image 2024-06-05 at 3.51.45 PM.jpeg"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
        <div className='d-md-flex justify-content-center justify-content-md-around align-items-center text-center py-2' style={{minHeight:'50px',background:'#0c1010'}}>
      <div className='d-md-flex d-block justify-content-center text-white' >       
      <div className="mx-2">
        <i className="bi bi-envelope mx-2" />
        royalnft007@gmail.com
        </div>
        <div className="mx-2">
      <i className="bi bi-phone mx-2" />
        +44 2081571253
        </div>
          
      </div>
      <div style={{color:'white'}} className='ms-2'>
        © RoyalNFT 2024
      </div>
    </div>
          {/* <footer>
            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <h4>About us</h4>
                    <p>
                      Welcome to Royal NFT, where timeless artistry meets modern
                      innovation. At Royal NFT, we specialize in creating
                      exquisite digital representations of handcrafted carpets,{" "}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <h4>Information</h4>
                    <ul className="address1">
                      <li>
                        <i className="bi bi-geo-alt-fill" />
                        New york
                      </li>
                      <li>
                        <i className="bi bi-envelope" />{" "}
                        <a href="mailto:#">demo@gmail.com</a>
                      </li>
                      <li>
                        <i className="bi bi-phone" />{" "}
                        <a href="tel:12 34 56 78 90">12 34 56 78 90</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <h4>Follow us</h4>
                    <ul className="social-icon">
                      <li>
                        <a href="#">
                          <i className="bi bi-facebook" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="bi bi-twitter-x" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <p className="copyright text-uppercase">Copyright © Royal NFT  2024</p>
                  </div>
                  <div class="col-sm-7">
                  <ul>
                      <li><a href="#">Home</a></li>
                      <li><a href="#">About Us</a></li>
                      <li><a href="#">Our services</a></li>
                      <li><a href="#">Contact Us</a></li>
                  </ul>
              </div>
                </div>
              </div>
            </div>
          </footer> */}
        </section>
      </div>

      {/* <div className="home-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mt-5">
              <div className="register-box">
                <h2 className="text-white d-flex align-items-center justify-content-center">
                  <img
                    src={`/assets/satoshi-logo.png`}
                    alt="logo"
                    style={{ width: "200px" }}
                  />
                </h2>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="register-img">
                      <img
                        src={RegisterImg}
                        className="img-fluid"
                        alt="register"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    <form onSubmit={handleIsregister}>
                      <button className="register-btn" type="submit">
                        REGISTER
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </Layout>
  );
};

export default Content;
