import React from "react";
// import styles from "./productDetailDesign.module.css";
import { ThreeDots } from "react-loader-spinner";

const ProceedModal = ({
  showModal,
  setShowModal,
  handleProceed,
  handleApprove,
  proceed,
  setProceed,
  spineLoading,
}) => {
  console.log("spineLoading", spineLoading);
  return (
    <>
      <div
        className={`modal fade${showModal ? " show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden={!showModal}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content bg-dark text-light ">
            <div className="modal-header" data-bs-theme="dark">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Proceed with wallet
              </h1>
              <button
                type="button"
                className="btn-close"
                onClick={() => (setProceed(null), setShowModal(false))}
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              In order to continue you will need to authorize the Marketplace
              contract to operate{" "}
              <span className="text-bold" style={{ color: " #32af75" }}>
                USDT
              </span>{" "}
              tokens on your behalf
            </div>
            {!proceed && (
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="ApproveBtn"
                  onClick={handleApprove}
                >
                  {!spineLoading ? (
                    <div className="ms-2">Approve</div>
                  ) : (
                    <ThreeDots
                      visible={true}
                      height="25"
                      width="30"
                      color="#7a62f9"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  )}
                </button>
              </div>
            )}
            {proceed && (
              <div className="modal-footer justify-content-end">
                <button
                  type="button"
                  className="ProceedBtn"
                  onClick={handleProceed}
                >
                  Proceed
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProceedModal;
