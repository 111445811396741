import nftImage1 from "./../image/nftImages/nft1.jpeg";
import nftImage2 from "./../image/nftImages/nft2.jpeg";
import nftImage3 from "./../image/nftImages/nft3.jpeg";
import nftImage4 from "./../image/nftImages/nft4.jpeg";
import nftImage5 from "./../image/nftImages/nft5.jpeg";
import nftImage6 from "./../image/nftImages/nft6.jpeg";
import nftImage7 from "./../image/nftImages/nft7.jpeg";
import nftImage8 from "./../image/nftImages/nft8.jpeg";
import nftImage9 from "./../image/nftImages/nft9.jpeg";
import nftImage10 from "./../image/nftImages/nft10.jpeg";
import nftImage11 from "./../image/nftImages/nft11.jpeg";
import nftImage12 from "./../image/nftImages/nft12.jpeg";
import nftImage13 from "./../image/nftImages/nft13.jpeg";
import nftImage14 from "./../image/nftImages/nft14.jpeg";

const allnftData = [
  {
    nftId: "1",
    imageUrl: nftImage1,
    nameOfNft: "Crystal Mycology1",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "2",
    imageUrl: nftImage2,
    nameOfNft: "Crystal Mycology2",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "3",
    imageUrl: nftImage3,
    nameOfNft: "Crystal Mycology3",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "4",
    imageUrl: nftImage4,
    nameOfNft: "Crystal Mycology4",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "5",
    imageUrl: nftImage5,
    nameOfNft: "Crystal Mycology5",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "6",
    imageUrl: nftImage6,
    nameOfNft: "Crystal Mycology6",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "7",
    imageUrl: nftImage7,
    nameOfNft: "Crystal Mycology7",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "8",
    imageUrl: nftImage8,
    nameOfNft: "Crystal Mycology8",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "9",
    imageUrl: nftImage9,
    nameOfNft: "Crystal Mycology9",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "10",
    imageUrl: nftImage10,
    nameOfNft: "Crystal Mycology10",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "11",
    imageUrl: nftImage11,
    nameOfNft: "Crystal Mycology11",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
  {
    nftId: "12",
    imageUrl: nftImage12,
    nameOfNft: "Crystal Mycology12",
    category: "Digital Art",
    description:
      "Beautifully crafted glass mushrooms, with delicate details and opalescent surfaces, standing tall amid a serene, natural setting.",
  },
];
export default allnftData;
