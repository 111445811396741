import React, { useEffect } from "react";
import UserPageSection from "./Content";
import nftImg from "../../../image/nftImages/nft1.jpeg";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import { backendUrl } from "../Abi/Content";
const Profile = () => {
  const [copytext, setCopyText] = useState("copy");
  const [profileData, setProfileData] = useState([]);
  let getLocalData = localStorage.getItem("auth");
  getLocalData = JSON.parse(getLocalData);
  const walletAddress = getLocalData.user?.address;
  const referralAddress = getLocalData.user?.referralAddress;

  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 8) +
        "...." +
        address.substr(address.length - 8, address.length)
      );
    }
    return address;
  };

  const handleCopy = () => {
    setCopyText("Copied!");
    let text = `https://royalnft.ai/?${walletAddress}`;
    text.trim();
    navigator.clipboard.writeText(text);
  };

  setTimeout(() => {
    setCopyText("copy");
  }, 2000);

  useEffect(() => {
    const handleGetProfileData = async () => {
      try {
        let getLocalData = localStorage.getItem("auth");
        getLocalData = JSON.parse(getLocalData);
        const jwtToken = getLocalData.user?.token;
        const resp = await axios.get(`/api/user/user-profile`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (resp?.status === 200) {
          if (resp?.data?.status === true) {
            setProfileData(resp?.data?.data);
          }
          console.log("ProfileData", resp);
        }
      } catch (error) {}
    };

    handleGetProfileData();
  }, []);

  return (
    <>
      <UserPageSection>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="referral-box">
                <p>Referral Link :</p>
                <p>
                  https://royalnft.ai/?
                  {start_and_end(walletAddress)}
                  <CopyToClipboard
                    text={` https://royalnft.ai/?${walletAddress}`}
                    onCopy={handleCopy}
                  >
                    <button className="btn copy-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="#fff"
                        class="bi bi-copy mx-1"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                        />
                      </svg>
                      {copytext}
                    </button>
                  </CopyToClipboard>
                </p>
              </div>
            </div>
          </div>
          <div className="row py-1">
            <div className="col-12">
              <div className="profile_main_banner">
                <div className="banner_profile">
                  <img
                    src="https://img.freepik.com/free-vector/gradient-network-connection-background_23-2148877163.jpg?size=626&ext=jpg&ga=GA1.1.397440520.1684217048&semt=ais_user"
                    alt="profileImag"
                  />
                </div>
                <div className="row align-items-center">
                  <div className="profile_main_box">
                    <div className="col-12 col-md-2">
                      <div className="profile_img">
                        <img
                          src="https://img.freepik.com/premium-photo/profile-icon-white-background_941097-162027.jpg?size=626&ext=jpg&ga=GA1.1.397440520.1684217048&semt=ais_user"
                          alt="profileImag"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-8">
                      <div className="profile_name">
                        <h4>
                          {" "}
                          <span>Address</span>
                          {start_and_end(walletAddress)}
                        </h4>
                        {referralAddress !== "" && (
                          <h4>
                            {" "}
                            <span>Referral</span>{" "}
                            {start_and_end(referralAddress)}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-12">
              <div className="profile_tab">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active profile_cart_tab "
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Buy Nfts
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link profile_cart_tab cus_active"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Referral Earning
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* card */}

          <div className="row py-2">
            <div className="col-12 col-md-10 mx-auto">
              <div
                className="profile_tab_card_box"
                // data-bs-toggle="modal"
                // data-bs-target="#staticBackdrop"
              >
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {profileData &&
                      profileData?.map((value, key) => {
                        return (
                          <div className="card card_prfile_product my-2">
                            <div className="row ">
                              <div className="col-md-7 col-12">
                                <div className="row align-items-center">
                                  <div className="col-md-6 col-12">
                                    <div className="prfile_cart_img">
                                      <img
                                        src={`${backendUrl}/api/user/getImages?imageName=${value?.asseturl}&pathName=ASSET_IMAGE_PATH_NFT`}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-12">
                                    <div className="prfile_cart_caption">
                                      <h3> {value?.assetName}</h3>
                                      <p>{value?.description}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 col-12">
                                <div className="prfile_data_caption">
                                  <div className="rank_box">
                                    <p>#{value?.assetId}</p>
                                  </div>
                                  {/* <div className="data_rank2box">
                                    <p>Type</p>
                                    <p>Digital Art</p>
                                  </div> */}

                                  <div className="data_rank2box">
                                    <p>Quantity</p>
                                    <p>{value?.totalQuantity}</p>
                                  </div>

                                  <div className="data_rank3box">
                                    <p>Price</p>
                                    <p>{value?.currentPrice}</p>
                                  </div>
                                  <div className="data_rank3box">
                                    <p>Total Price</p>
                                    <p>{value?.totalPrice}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="card ref-cuscard">
                      <div className="cad body">
                        {/* <div className="refmaincontainer">
                          <p>
                            <span>referal Address :</span> 0x65c0cb....14E1372D
                          </p>
                          <p>
                            <span>referral income :</span> 10
                          </p>
                          <p>
                            <span>Transaction Hash :</span> 0x65c0cb....14E1372D
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  {/* <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    ...
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* modal */}

        {/* <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-lg">
            <div className="modal-content cus_modal_content_profile">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  A red Stylized 3D
                </h5>
                <button
                  type="button"
                  className="btn-close close_btn_profile"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="profile_pop_main_box">
                        <div className="profile_pop_img">
                          <img src="/assets/Pic-3.png" alt="" />
                        </div>
                        <p>
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit. Commodi, optio!
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="prfile_data_caption2">
                        <div className="data_rank4box">
                          <p>Purchased</p>
                          <p>22.06.23</p>
                        </div>

                        <div className="rank_main_box">
                          <p>Token ID</p>
                          <div className="rank_box">
                            <p>#56476857</p>
                          </div>
                        </div>

                        <div className="data_rankpopupbox">
                          <p>Type</p>
                          <p>Digital Art</p>
                        </div>

                        <div className="pop_input_box">
                          <p>DEOD</p>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text input_logo"
                              id="basic-addon1"
                            >
                              <img src="/assets/logoicon.png" alt="" />
                            </span>
                            <input
                              type="number"
                              className="form-control deod_num"
                              placeholder="Deod"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                        <div className="pop_input_box">
                          <p>Dollar</p>
                          <div className="input-group mb-3">
                            <span
                              className="input-group-text input_logo2"
                              id="basic-addon1"
                            >
                              <img src="/assets/dollar-logo.png" alt="" />
                            </span>
                            <input
                              type="text"
                              className="form-control deod_num"
                              placeholder="$ 100"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={"$100"}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="pop_input_box">
                          <p>Expairy Date</p>
                          <div className="input-group mb-3">
                          
                            <input
                              type="date"
                              className="form-control datebox"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </UserPageSection>
    </>
  );
};

export default Profile;
