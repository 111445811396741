import React, { useEffect, useState } from "react";
import UserPageSection from "./Content";
import allnftData from "../../../data";
import { useNavigate } from "react-router-dom";
import { logDOM } from "@testing-library/react";
import { ethers } from "ethers";
import {
  RoyalNftContract,
  RoyalNftContractAbi,
  backendUrl,
} from "../Abi/Content";
import { toast } from "react-toastify";
import Loading from "../LoaderPageSection/Loading";
import axios from "axios";
const Marketplace = () => {
  const navigate = useNavigate();
  const [nftData, setNftData] = useState([]);
  const [mainLoading, setMainLoading] = useState(false);
  console.log("nftData", nftData);
  useEffect(() => {
    const handleGetBUSDRate = async () => {
      debugger;
      setMainLoading(true);
      try {
        let getLocalData = localStorage.getItem("auth");
        getLocalData = JSON.parse(getLocalData);
        const jwtToken = getLocalData.user?.token;
        const resp = await axios.get(`/api/asset/get-all-authassets`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (resp.status === 200) {
          if (resp?.data?.status === true) {
            const AllNftApiData = resp?.data?.data;
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const ROYALNFTBUY = new ethers.Contract(
              RoyalNftContract,
              RoyalNftContractAbi,
              signer
            );
            let getBUSDPrice = await ROYALNFTBUY.getPrice(1);
            getBUSDPrice = ethers.utils.formatUnits(getBUSDPrice[0], 6);
            const updatedNftData = AllNftApiData.map((nft) => ({
              ...nft,
              BUSDprice: +getBUSDPrice,
            }));

            setNftData(updatedNftData);
          }
        }
      } catch (error) {
        if (error.response.status === 400) {
          var err_400 = error?.response?.data?.message;
          toast.error(err_400);
        } else if (error.response.status === 401) {
          const err_401 = error?.response?.data?.message;
          toast.error(err_401);
        } else if (error.response.status === 404) {
          const err_404 = error?.response?.data?.message;
          toast.error(err_404);
        } else if (error.response.status === 500) {
          const err_500 = error?.response?.data?.message;
          toast.error(err_500);
        } else {
          console.log("Error in Register", error);
          toast.error("something went wrong");
        }
      }
      setMainLoading(false);

      try {
      } catch (error) {
        const output = JSON.stringify(error);
        const outputInObj = JSON.parse(output);
        const err = outputInObj.reason;
        toast.error(err);
      }
    };
    handleGetBUSDRate();
  }, []);
  return (
    <UserPageSection>
      {mainLoading && <Loading loading={mainLoading} />}
      <div className="container">
        <div className="row g-3">
          <div className="col-12">
            <h5 className="title_text">
              {" "}
              All NFTs <span className="ml-2 p-2 total_count">11</span>
            </h5>
          </div>
          {nftData?.map((value, key) => {
            return (
              <>
                <div className="col-12 col-md-3">
                  <div className="card_new">
                    <div className="card_new_img">
                      <img
                        src={`${backendUrl}/api/user/getImages?imageName=${value?.asseturl[0]}&pathName=ASSET_IMAGE_PATH_NFT`}
                        alt=""
                      />
                    </div>
                    <div className="card_text">
                      <h5>{value?.assetName}</h5>
                      <div className="row">
                        <div className="col-6">
                          <div className="price_box my-4">
                            <img
                              src="https://www.decentrawood.com/assets/ether-logo.svg"
                              alt=""
                            />
                            <p className="text-white">{value?.BUSDprice}</p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="buy_button"
                        onClick={() =>
                          navigate(`/nft-details/${value?.assetId}`)
                        }
                      >
                        <span>Buy Now</span>
                      </button>
                    </div>
                    {/* <div
                      className="cart_new"
                      // onClick={() => handleaddToCart(value)}
                    >
                      <i class="fa fa-shopping-cart"></i>
                    </div> */}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </UserPageSection>
  );
};

export default Marketplace;
