// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminbox {
    width: 700px;
    margin: auto;
    color: #fff;
}

.cus-navlink {
    width: 50%;
    font-weight: 600 !important;
}

.modal-backdrop.fade {
    z-index: -1;
}

.adminbox-caption {
    color: #fff;

}

.cus-modal {
    color: black;
}

.background-active-bg {
    background-color: #fff !important;
    color: black !important;
    border-radius: 0 !important;
}

/* .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff !important;
    background-color: #0d6efd !important;
    border-radius: 0 !important;
} */

.cus-buttontex {
    color: "red";
}

@media only screen and (max-width: 600px) {
    .adminbox {
        width: 100%;
        margin: auto;
        color: #fff;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/sections/AdminPageSection/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,UAAU;IACV,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;;AAEf;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iCAAiC;IACjC,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;;;;;GAKG;;AAEH;IACI,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;QACX,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":[".adminbox {\n    width: 700px;\n    margin: auto;\n    color: #fff;\n}\n\n.cus-navlink {\n    width: 50%;\n    font-weight: 600 !important;\n}\n\n.modal-backdrop.fade {\n    z-index: -1;\n}\n\n.adminbox-caption {\n    color: #fff;\n\n}\n\n.cus-modal {\n    color: black;\n}\n\n.background-active-bg {\n    background-color: #fff !important;\n    color: black !important;\n    border-radius: 0 !important;\n}\n\n/* .nav-pills .nav-link.active,\n.nav-pills .show>.nav-link {\n    color: #fff !important;\n    background-color: #0d6efd !important;\n    border-radius: 0 !important;\n} */\n\n.cus-buttontex {\n    color: \"red\";\n}\n\n@media only screen and (max-width: 600px) {\n    .adminbox {\n        width: 100%;\n        margin: auto;\n        color: #fff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
