// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.856);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8000000;
}

.navbar {
    background-color: black;
    /* color: #fff; */
}

.loading p {
    font-size: 3em;
    animation: anim 1s infinite;
}

@keyframes anim {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/sections/LoaderPageSection/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,gCAAgC;IAChC,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,2BAA2B;AAC/B;;AAEA;IACI;QACI,UAAU;IACd;;IAEA;QACI,UAAU;IACd;AACJ","sourcesContent":[".loading {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    background: rgba(0, 0, 0, 0.856);\n    z-index: 100;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 8000000;\n}\n\n.navbar {\n    background-color: black;\n    /* color: #fff; */\n}\n\n.loading p {\n    font-size: 3em;\n    animation: anim 1s infinite;\n}\n\n@keyframes anim {\n    0% {\n        opacity: 0;\n    }\n\n    100% {\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
