import React, { useEffect, useState } from "react";
import UserPageSection from "./Content";
import { useNavigate, useParams } from "react-router-dom";
import allnftData from "../../../data";
import ProceedModal from "./proceedModal";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { BigNumber } from "bignumber.js";
import {
  BusdContract,
  BusdContractAbi,
  RoyalNftContract,
  RoyalNftContractAbi,
  backendUrl,
} from "../Abi/Content";
import axios from "axios";
import Loading from "../LoaderPageSection/Loading";

const NftDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [spineLoading, setSpineLoadding] = useState(false);
  const [singleFilterData, setSingleFilterData] = useState({});
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [totalUsdtPrice, setTotalUsdtPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const handleApprove = async () => {
    debugger;
    setSpineLoadding(true);
    let getLocalData = localStorage.getItem("auth");
    getLocalData = JSON.parse(getLocalData);
    console.log("getLocalData", getLocalData.user?.address);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const accounts = await signer.getAddress();
      const addToLower = accounts.toLowerCase();

      if (getLocalData.user?.address === addToLower) {
        const BUSDApprove = new ethers.Contract(
          BusdContract,
          BusdContractAbi,
          signer
        );
        // let a = ethers.utils
        //   .parseUnits(totalUsdtPrice.toString(), "ether")
        //   .toString();

        let x;
        x = (totalUsdtPrice + 1) * 10 ** 6;
        let z = new BigNumber(x);
        let a = z.toFixed();

        const txn = await BUSDApprove.approve(RoyalNftContract, a);
        const receipttxn = await txn.wait();
        if (receipttxn.status !== 1) {
          alert("error message");
        } else {
          setProceed(true);
        }
      } else {
        toast.error("please connect correct wallet", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      const output = JSON.stringify(error);
      const outputInObj = JSON.parse(output);
      const err = outputInObj.reason;
      toast.error(err);
    }
    setSpineLoadding(false);
  };
  const handleProceed = async () => {
    setMainLoading(true);
    let getLocalData = localStorage.getItem("auth");
    getLocalData = JSON.parse(getLocalData);

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const ROYALNFTBUY = new ethers.Contract(
        RoyalNftContract,
        RoyalNftContractAbi,
        signer
      );
      const checkRerral = getLocalData.user?.referralAddress;
      const referralAddress =
        checkRerral === ""
          ? "0x0000000000000000000000000000000000000000"
          : checkRerral;
      const txn = await ROYALNFTBUY.mintNft(
        singleFilterData?.assetId,
        quantity,
        referralAddress
      );
      const receipttxn = await txn.wait();
      if (receipttxn.status !== 1) {
        toast.error("Error");
      } else {
        const walletAddress = getLocalData.user?.address;
        await handleBuyApi(receipttxn, walletAddress);
        navigate("/marketplace");
        toast.success("NFT Buy Successfully");
      }
    } catch (error) {
      const output = JSON.stringify(error);
      const outputInObj = JSON.parse(output);
      const err = outputInObj.reason;
      toast.error(err);
    }
    setMainLoading(false);
  };

  const handleBuyApi = async (reciept, walletAddress) => {
    debugger;

    try {
      const api = `/api/user/order-assets`;
      const data = {
        accountId: walletAddress,
        assetId: singleFilterData?.assetId,
        assetName: singleFilterData?.assetName,
        currentPrice: usdtPrice,
        quantity: quantity,
        totalPrice: totalUsdtPrice,
        transcationHash: reciept.transactionHash,
      };
      let getLocalData = localStorage.getItem("auth");
      getLocalData = JSON.parse(getLocalData);
      const jwtToken = getLocalData.user?.token;
      axios
        .post(api, data, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        })
        .then((res) => {
          console.log("handleBuyMintedNFT", res);
        });
    } catch (error) {
      toast.error("Error");
    }
  };
  useEffect(() => {
    const handleQuantityPrice = async () => {
      if (singleFilterData?.BUSDprice) {
        try {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const ROYALNFTBUY = new ethers.Contract(
            RoyalNftContract,
            RoyalNftContractAbi,
            signer
          );
          let totalBUSDPrice = await ROYALNFTBUY.getPrice(quantity);
          totalBUSDPrice = ethers.utils.formatUnits(totalBUSDPrice[0], 6);
          setTotalUsdtPrice(+totalBUSDPrice);
        } catch (error) {}
      }
    };
    handleQuantityPrice();
  }, [quantity, singleFilterData]);

  useEffect(() => {
    const handleSetBusdPrice = async () => {
      try {
        let getLocalData = localStorage.getItem("auth");
        getLocalData = JSON.parse(getLocalData);
        const jwtToken = getLocalData.user?.token;
        const resp = await axios.get(`/api/asset/get-auth-asset/${id}`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
        if (resp?.status === 200) {
          if (resp?.data?.status === true) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const ROYALNFTBUY = new ethers.Contract(
              RoyalNftContract,
              RoyalNftContractAbi,
              signer
            );
            let getBUSDPrice = await ROYALNFTBUY.getPrice(1);
            getBUSDPrice = ethers.utils.formatUnits(getBUSDPrice[0], 6);
            const SinglePerticularData = resp?.data?.data;
            const updateFilterData = SinglePerticularData.map((nft) => ({
              ...nft,
              BUSDprice: +getBUSDPrice,
            }));
            if (updateFilterData.length > 0) {
              setSingleFilterData(updateFilterData[0]);
              const price = Number(updateFilterData[0].BUSDprice);
              setUsdtPrice(price);
              setTotalUsdtPrice(price);
            }
          }
        }
      } catch (error) {}
    };

    handleSetBusdPrice();
  }, [id]);

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };
  console.log(
    `${backendUrl}/api/user/getImages?imageName=${
      singleFilterData && singleFilterData?.asseturl
    }&pathName=ASSET_IMAGE_PATH_NFT`
  );
  return (
    <UserPageSection>
      {mainLoading && <Loading loading={mainLoading} />}
      <div className="container">
        <div className="row my-4">
          <div className="col-md-4 col-12">
            <div className="card card_img_box">
              <div className="cart_img">
                <img
                  src={`${backendUrl}/api/user/getImages?imageName=${singleFilterData?.asseturl}&pathName=ASSET_IMAGE_PATH_NFT`}
                  alt="000"
                />
              </div>
              <div className="card-body card_body_product">
                <div className="row">
                  <div className="col-5">
                    <div className="cart_price_box">
                      <p>Current Price</p>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="cart_price">
                      <div className="doad_price">
                        <img
                          src="https://www.decentrawood.com/assets/ether-logo.svg"
                          alt=""
                          className="mx-2"
                        />
                        <p>{usdtPrice}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-5">
                    <div className="cart_price_box">
                      <p>Total Usdt Price</p>
                    </div>
                  </div>
                  <div className="col-7">
                    <div className="cart_price">
                      <div className="doad_price">
                        <img
                          src="https://www.decentrawood.com/assets/ether-logo.svg"
                          alt=""
                          className="mx-2"
                        />
                        <p>{totalUsdtPrice}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-5">
                        <div className="buy_btn_box">
                          <button
                            className="buy_btn"
                            onClick={() => setShowModal(true)}
                          >
                            Buy Now
                          </button>
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="cart_icon">
                          <button className="btn btn p-1 shadow-none border-0">
                            <i className="fa-solid fa-cart-shopping"></i>
                          </button>
                          <button className="btn btn p-1 cus shadow-none border-0 clr_heart">
                            <i className="fa-solid fa-heart"></i>
                          </button>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="count_box">
                          <button
                            className="add_btn"
                            onClick={decreaseQuantity}
                          >
                            <i className="fa-solid fa-minus"></i>
                          </button>
                          <input
                            className="count_input"
                            type="text"
                            value={quantity}
                            readOnly
                          />
                          <button
                            className="add_btn"
                            onClick={increaseQuantity}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-12">
            <div className="product_Details">
              <h4>{singleFilterData?.assetName}</h4>
              <div className="within_caption">
                <p>250 Minted</p>
                <span>|</span>
                <p>Listed</p>
                <span>|</span>
                <p>Owner</p>
              </div>

              <div className="sec_caption_nft_main">
                <div className="row">
                  <div className="col-12">
                    <div className="heding_nft">
                      <h4>Details</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec_caption_nft_main">
                <div className="row">
                  <div className="col-12">
                    <div className="caption_details_box2">
                      <div className="row my-3 nft_details">
                        <div className="col-md-4 col-5">
                          <div className="nft_within_details2">
                            <p>Blockchain</p>
                            <div className="nft_name_logo2">
                              <span className="dot_box"></span>
                              <p>Polygon</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-7">
                          <div className="nft_within_details2">
                            <p>NFT ID</p>
                            <div className="nft_name_logo2">
                              <p>{singleFilterData?.assetId}</p>
                              <i className="fa-regular fa-copy "></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="sec_caption_nft_description">
                <div className="row">
                  <div className="col-12">
                    <div className="heding_description">
                      <h4>Description</h4>
                    </div>
                    <p>{singleFilterData?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ProceedModal
        showModal={showModal}
        setShowModal={setShowModal}
        proceed={proceed}
        setProceed={setProceed}
        handleApprove={handleApprove}
        handleProceed={handleProceed}
        spineLoading={spineLoading}
      />
    </UserPageSection>
  );
};

export default NftDetails;
